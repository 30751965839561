<template>
    <div>
        <el-dialog
            title="评价配置全部车场参数"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal='false'
            custom-class='dialog'
			@close='resetForm'
            >
            <el-form ref="form" :model="form" label-width="145px" :rules="rules" >
				<el-form-item label="车主历史交易次数≥" prop="transactionNumber">
					<el-input
					 :maxlength='5'
					 v-model.trim="form.transactionNumber"
					 placeholder="请输入交易次数"
					 style="width:150px; margin:0 8px 0 0"
					 @keyup.native="inputNumberFilter('transactionNumber',$event)"
					 @blur="inputNumberFilter('transactionNumber',$event)"
					>
					</el-input>
					<span style="margin:0 12px 0 0">次</span>
					<el-tooltip class="item" effect="dark" content="参数默认为5次，“≥n”代表正常，若为“0”表示不限制" placement="top">
						<i class="el-icon-question" style="color:#666; font-size:15px"></i>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="车主注册时间≥" prop="exceedDateNumber">
					<el-input
					 :maxlength='5'
					 v-model.trim="form.exceedDateNumber"
					 placeholder="请输入注册时间"
					 style="width:150px; margin:0 8px 0 0"
					 @keyup.native="inputNumberFilter('exceedDateNumber',$event)"
					 @blur="inputNumberFilter('exceedDateNumber',$event)"
					>
					</el-input>
					<span style="margin:0 12px 0 0">天</span>
					<el-tooltip class="item" effect="dark" content="参数默认为30天，“≥n”代表正常，若为“0”表示不限制" placement="top">
						<i class="el-icon-question" style="color:#666; font-size:15px"></i>
					</el-tooltip>
				</el-form-item>
            </el-form>
			<p class="tisInfo">须知：修改默认参数配置会对全局车场评分有影响，点击确定立即生效，请谨慎修改!</p>
            <div slot="footer">
                <el-button type="primary" @click="save" :loading="saveLoading">确定</el-button>
                <el-button @click="resetForm">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
   data () {
       return {
           dialogVisible: false,
		   saveLoading: false,
		   form: {
		   	 transactionNumber: '',
			 exceedDateNumber: ''
		   },
		   rules: {
		   	 transactionNumber: [
			   {
				   required: true,
				   message: '交易次数必填',
				   trigger: 'blur'
			  }
			 ],
			 exceedDateNumber: [
			   {
				   required: true,
				   message: '注册时间必填',
				   trigger: 'blur'
			  }
			 ]
		   },
		   formType: 'add'
       }
   },
   methods: {
       // 打开窗口
       open () {
           this.dialogVisible = true;
		   for (let i of Object.keys(this.form)) {
				 this.form[i] = '';
		   }
		   // this.form.transactionNumber = 5;
		   // this.form.exceedDateNumber = 30;
		   this.searchData();
       },
	   // 取消
       resetForm () {
          this.dialogVisible = false;
		  this.$refs.form.resetFields();
       },
	   //  数字过滤
	  inputNumberFilter (name, event) {
	    let value = event.target.value;
		value = value.replace(/[^\d]/g, "");
		if (value.length > 1) {
			value = value.replace(/^0/g, "");
		}
		this.form[name] = value;
	  },
	  // 查询
	  searchData() {
		  let url = "/acb/2.0/memberParkEvaluation/getEvaluationRule";
		  this.$axios.get(url).then((res) => {
			  if (res.state == 0) {
					if (res.value) {
					  // console.log("A1");
					  this.form.transactionNumber = res.value.transactionNumber;
					  this.form.exceedDateNumber = res.value.exceedDateNumber;
					  this.formType = 'edit';
					} else {
					  // console.log("A2");
					  this.$refs.form.resetFields();
					  this.form.transactionNumber = 5;
					  this.form.exceedDateNumber = 30;
					  this.formType = 'add';
					}
			  } else {
					this.$alert(res.desc, this.$t('pop_up.Tips'), {
					  	confirmButtonText: this.$t('pop_up.Determine'),
					})
			  }
		 })
	  },
	  // 保存
	  save () {
	  	 this.$refs.form.validate((valid) => {
              if (valid) {
			      this.saveLoading = true;
				  let url
				  if (this.formType == 'add') {
				  	url = '/acb/2.0/memberParkEvaluation/addEvaluationRule'
				  } else {
				  	url = '/acb/2.0/memberParkEvaluation/updateEvaluationRule'
				  }
                  this.$axios.post(url, {
					   data: {
						  ...this.form
					   }
				  }).then(res => {
				    this.saveLoading = false;
					if (res.state == 0) {
						    this.$alert("操作成功！", this.$t('pop_up.Tips'), {
							  confirmButtonText: this.$t('pop_up.Determine'),
							}).then(() => {
								this.$emit('searchData');
								this.dialogVisible = false;
							});
					} else {
						this.$alert(res.desc, this.$t('pop_up.Tips'), {
							confirmButtonText: this.$t('pop_up.Determine')
						})
					}
				  }).catch(error => {
				  	 this.saveLoading = false;
				  })
              } else {
			  	return false;
			  }
		 })
	  }
   },
   created () {
   }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.tisInfo
	font-size 12px
	color #ff0000
	width 98%
</style>

<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          label-width="80px"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1,2"
                  :parkNameValue="parkNameValue"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_coding')">
                <el-input
                  v-model.trim="formInline.parkAlias"
                  placeholder="请输入道路编码"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.charge_type')">
                <el-select v-model.trim="formInline.scopeId" filterable placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in parkScope"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Commercial_status')">
                <el-select
                  v-model.trim="formInline.commercialStatus"
                  placeholder="请选择"
                  filterable
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in commercialStatus"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_hierarchy')">
                <el-select v-model.trim="formInline.slaveRelations" placeholder="请选择" filterable>
                  <el-option label="全部" value=""></el-option>
                  <el-option label="道路" value="1"></el-option>
                  <el-option label="路段" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="reset()"
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t("button.reset") }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.evaluate ||
              $route.meta.authority.button.modifyAudit ||
              $route.meta.authority.button.export
            "
          ></div>
          <div
            class="col_box h44"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.evaluate ||
              $route.meta.authority.button.modifyAudit ||
              $route.meta.authority.button.export
            "
          >
            <div class="col_left">
              <el-button
                v-if="$route.meta.authority.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="handleParkAdd"
                >{{ $t("button.addto") }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-document-copy"
                plain
                @click="setComment"
                v-if="$route.meta.authority.button.evaluate"
                >{{ $t("button.Evaluate_batch_configuration") }}</el-button
              >
              <el-button
                type="info"
                @click="addUpdata"
                v-if="$route.meta.authority.button.modifyAudit"
                >{{ $t("button.examine_and_verify") }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button
                v-if="$route.meta.authority.button.export"
                type="info"
                plain
                @click="exportFile"
              >
                <i class="el-icon-upload2"></i>&nbsp;{{ $t("button.export") }}
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <!-- <el-button @click="drawer = true" type="primary" style="margin-left: 16px;">
          <i :class="'iconfont icon icon-ico-chechangguanli'"></i>
          点我打开
        </el-button> -->
        <el-drawer title="我是标题" :visible.sync="drawer" :direction="direction">
          <addNoClosePark v-if="drawer" />
        </el-drawer>

        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            fixed="right"
            v-if="
              $route.meta.authority.button.view ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.configure
            "
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-dropdown"
                align="center"
                v-show="
                  $route.meta.authority.button.view ||
                  $route.meta.authority.button.edit ||
                  ($route.meta.authority.button.configure && scope.row.slaveRelation != 1)
                "
                @command="handleCommand($event, scope.row)"
                width="80"
              >
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="$route.meta.authority.button.configure"
                    v-show="scope.row.slaveRelation != 1"
                    >配置</el-dropdown-item
                  >
                </el-dropdown-menu>
              </AuthorityComponent>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!--评价配置-->
        <comment-setup ref="commentSet" @searchData="searchData"></comment-setup>
      </div>
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import addNoClosePark from "@/views/parkManage/parkList/addNoClosePark";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import commentSetup from "./parkList/commentSet";
export default {
  name: "parkList",
  data() {
    return {
      drawer: false,
      direction: "rtl",
      icon: require("@/assets/img/directionDown.png"),
      parkNameValue: "",
      // temParkData: {},
      streetList: [],
      street: "",
      areaId: "",
      streetId: "",
      area: "",
      state1: "",
      parkType: [],
      parkScope: [],
      commercialStatus: [],
      parkData: [],
      index: 0,
      total: 0,
      parkPage: 1,
      parkPageSize: 15,
      pageSize: 15,
      page: 1,
      loading: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "parkAlias",
          // label: this.$t("list.Road_coding"),
          label: this.$t("list.Road_coding"),
          width: "",
        },
        {
          prop: "parkName",
          // label: this.$t("list.Road_name"),
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "parkTypeName",
          // label: this.$t("list.Road_type"),
          label: this.$t("list.Road_type"),
          width: "120",
        },
        {
          prop: "scopeName",
          // label: this.$t("list.charge_type"),
          label: this.$t("list.charge_type"),
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          // label: this.$t("list.region"),
        },
        {
          prop: "amount",
          label: this.$t("list.total_parking_number"),
          // label: this.$t("list.total_parking_number"),
        },
        {
          prop: "slaveRelation",
          label: this.$t("list.Road_hierarchy"),
          // label: this.$t("list.Road_hierarchy"),
          width: "",
          formatter: (row) => {
            if (row.slaveRelation == 2) {
              return "路段";
            } else {
              return "道路";
            }
          },
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          // label: this.$t("list.Merchant_Name"),
        },
        {
          prop: "commercialStatus",
          label: this.$t("list.Commercial_status"),
          // label: this.$t("list.Commercial_status"),
        },
      ],
      areaIds: "",
      tableData: [],
      formInline: {
        parkAlias: "",
        areaId: "",
        operationId: "",
        parkType: "",
        commercialStatus: "",
        scopeId: "",
        slaveRelations: "",
        parkName: "",
        parkAccessType: "",
      },
    };
  },
  methods: {
    // 重置
    reset() {
      this.formInline = {
        parkAlias: "",
        areaId: "",
        parkId: "",
        operationId: "",
        parkType: "",
        commercialStatus: "",
        scopeId: "",
        slaveRelations: "",
        parkName: "",
        parkAccessType: "",
      };
      this.$refs.cascader.clear();
      this.$refs.parkInput.setShowVal("");
    },
    // 审核修改
    addUpdata() {
      this.$router.push("/parkEocList");
      this.$setAuthority("parkEocList", this.$route.meta.authority.subPage.modifyAudit);
    },
    addUpdata1() {
      // 同步接入车场
      this.$router.push("/synchronousCarYard");
      this.$setAuthority("synchronousCarYard", this.$route.meta.authority.subPage.modifyAudit);
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    exportFile() {
      let opt = {};
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      opt = {
        page: this.page,
        pageSize: this.pageSize,
        ...data,
      };
      exportExcelNew("/acb/2.0/park/export", opt);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkType = data["parkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 批量配置评价
    setComment() {
      this.$refs.commentSet.open();
    },
    handleParkAdd() {
      this.$router.push("/addNoClosePark");
    },
    // handleCommand2(cmd, data) {
    //   if (cmd == "a") {
    //     this.$router.push("/addNoClosePark");
    //   } else if (cmd == "b") {
    //     this.$router.push("/addClosePark");
    //   }
    // },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({ path: "/addNoClosePark", query: data });
      }
      if (cmd == "b") {
        sessionStorage.subPageAuthority = JSON.stringify(this.$route.meta.authority);
        if (data.slaveRelation != 2) {
          this.$router.push({ path: "/noCloseParentDetail", query: data });
        } else {
          this.$router.push({ path: "/noCloseChildDetail", query: data });
        }
      }
      if (cmd == "c") {
        console.log(this.$route.meta);
        console.log("-->", this.$route.meta.authority.subPage.configure);
        this.$setAuthority("parkConfig", this.$route.meta.authority.subPage.configure);
        // sessionStorage.subPageAuthority = JSON.stringify(this.$route.meta.authority.subPage.configure);
        this.$router.push({ path: "/parkConfig", query: data });
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = "/acb/2.0/park/list";
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...data,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
  },
  beforeDestroy() {},
  components: {
    commentSetup,
    myComponent,
    addNoClosePark,
  },
  created() {
    this.queryTypeList();
    this.getTenantList();
  },
  mounted() {
    this.searchData();
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
  activated() {
    this.searchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
